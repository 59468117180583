import { requestApi, requestPublicApi } from '../../services/HttpRequester';

export function authenticate(username, password, code) {
  return requestPublicApi({
    method: 'POST',
    url: '/auth',
    data: {
      ...(!code && { Email: username, Password: password }),
      ...(code && { ssoCode: code })
    },
    headers: {
      accept: '*/*'
    }
  });
}

export function refreshTokenAuth(refreshToken) {
  return requestPublicApi({
    method: 'POST',
    url: '/auth',
    data: {
      RefreshToken: refreshToken
    },
    headers: {
      accept: '*/*'
    }
  });
}

export function verifyCode(username, password, code) {
  return requestPublicApi({
    method: 'POST',
    url: '/auth',
    data: {
      Email: username,
      Password: password,
      code: code
    },
    headers: {
      accept: '*/*'
    }
  });
}

export function updateRootAccessKeys(payload) {
  return requestApi({
    method: 'PUT',
    url: '/access-keys/root',
    data: {
      accessKeyId: payload.accessKey,
      secretAccessKey: payload.secretKey
    }
  });
}

export function resetRootAccessKeys() {
  return requestApi({
    method: 'POST',
    url: '/access-keys/reset'
  });
}

export function changePassword(oldPassword, newPassword, isFirstTime) {
  return requestApi({
    method: 'PUT',
    url: '/users/change-password',
    data: {
      oldPassword: oldPassword,
      newPassword: newPassword,
      isFirstTime: isFirstTime
    }
  });
}

export function changeMemberPassword(newPassword, id) {
  return requestApi({
    method: 'PUT',
    url: '/members/'.concat(id).concat('/change-password'),
    data: {
      newPassword: newPassword
    }
  });
}

export function getPermissions() {
  return requestApi({
    method: 'GET',
    url: '/user/permissions'
  });
}

export function getSubAccount() {
  return requestApi({
    method: 'GET',
    url: '/user/sub-account'
  });
}
