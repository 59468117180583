/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import jwt from 'jwt-decode';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { showToast } from '../../../../hooks/show-toast';
import { LoaderWrap } from '../../../common/loader/Loader';
import { authenticate, getPermissions } from '../../endpoints';
import { convertCammelCaseToTitleCase } from '../../../../utils/format-string-cases';
import { initialValues, validationSchema } from '../helpers';
import { Divider, OrText, OrTextContainer } from '../styles';
import FormInput from '../../../common/form-input';
import Button from '../../../common/button/Button';
import Box from '../../../common/box/Box';

const LoginForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const errorDesc = queryParams.get('error_description');
  const [displayLoader, setDisplayLoader] = useState(false);
  const [displaySSOLoginLoader, setDisplaySSOLoginLoader] = useState(false);
  const { processSignIn, setAuthLoader } = useStoreActions(
    actions => actions.authentication
  );
  const { authLoader } = useStoreState(state => state.authentication);

  useEffect(() => {
    if (errorDesc?.length > 0) {
      showToast(
        { message: convertCammelCaseToTitleCase(errorDesc), success: false },
        'error'
      );
    }
  }, []);

  useEffect(() => {
    if (code?.length > 0) {
      setAuthLoader(true);
      onSubmit();
    }
  }, [code]);

  const formik = {
    ...useFormik({
      validationSchema: validationSchema,
      initialValues: initialValues,
      enableReinitialize: true,
      onSubmit: fieldValues => onSubmit(fieldValues)
    })
  };

  const loginWithSSORedirect = () => {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    window.location.href = `${process.env.REACT_APP_SSO_DOMAIN}/authorize?response_type=code&client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&redirect_uri=${baseUrl}${process.env.REACT_APP_SSO_REDIRECT_URL}&scope=email openid profile`;
  };

  const onSubmit = () => {
    const splittedCode = code?.length > 0 ? code : null;
    const username = formik?.values?.username;
    const password = formik?.values?.password;
    if (splittedCode) setDisplaySSOLoginLoader(true);
    setDisplayLoader(true);
    authenticate(username, password, splittedCode)
      .then(([res]) => {
        if (res.success && res.data) {
          if (!res.data.mfaEnabled) {
            if (jwt(res.data.AccessToken)?.user?.IsSamlSsoWithoutSignOut) {
              localStorage.setItem(
                'IsSamlSsoWithoutSignOut',
                jwt(res.data.AccessToken)?.user?.IsSamlSsoWithoutSignOut
              );
            }
            if (jwt(res.data.AccessToken)?.user?.IsSsoUser) {
              localStorage.setItem(
                'userIsSso',
                jwt(res.data.AccessToken)?.user?.IsSsoUser
              );
            }
            if (jwt(res.data.AccessToken)?.user?.IsFirstLogin) {
              setDisplayLoader(false);
              setDisplaySSOLoginLoader(false);
              processSignIn(res.data);
              navigate('/reset-password');
            } else {
              processSignIn(res.data);
              const hasKeys = res?.data?.HasRootAcessKey;
              const jwtToken = jwt(res.data.AccessToken);
              getPermissions().then(([res]) => {
                setDisplayLoader(false);
                setDisplaySSOLoginLoader(false);
                if (res != null) {
                  localStorage.setItem('Permissions', JSON.stringify(res.data));
                  if (!hasKeys && !jwtToken?.roles.includes('ROOT')) {
                    setAuthLoader(false);
                    navigate('/no-access-keys');
                  } else {
                    navigate(hasKeys ? '/buckets' : '/access-keys');
                  }
                }
              });
            }
          } else {
            setDisplayLoader(false);
            setDisplaySSOLoginLoader(false);
            setAuthLoader(false);
            navigate('/verify-code', {
              state: {
                username,
                password
              }
            });
          }
        } else {
          setDisplayLoader(false);
          setDisplaySSOLoginLoader(false);
          setAuthLoader(false);
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: false
            },
            'error'
          );
        }
      })
      .catch(err => {
        showToast(
          {
            message: err?.code || err?.Code,
            params: err?.params || null,
            success: false
          },
          'error'
        );
        setAuthLoader(false);
      })
      .finally(() => {
        if (code?.length > 0) {
          const newParams = new URLSearchParams(location.search);
          newParams.delete('code');
          const newPath = `${location.pathname}?${newParams.toString()}`;
          navigate(newPath, { replace: true });
        }
      });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <FormInput
          name="username"
          label={t('emailOrUserName')}
          formik={formik}
          disabled={code?.length > 0}
          readOnly={code?.length > 0}
        />
        <FormInput
          label={t('password')}
          name="password"
          formik={formik}
          disabled={code?.length > 0}
          readOnly={code?.length > 0}
          inputType={code?.length > 0 ? 'password' : 'password'}
        />
        <Button
          disabled={code?.length > 0 || displaySSOLoginLoader}
          isLoading={!displaySSOLoginLoader && displayLoader}
          w="100%"
          primary
          type={'submit'}
        >
          {t('signIn')}
        </Button>
        <Divider>
          <OrTextContainer>
            <OrText>{t('or')}</OrText>
          </OrTextContainer>
        </Divider>
        <Button
          disabled={displaySSOLoginLoader}
          onClick={() => loginWithSSORedirect()}
          isLoading={code?.length > 0}
          w="100%"
          primary
          type="button"
        >
          {t('signInSSO')}
        </Button>
      </Box>
      {authLoader && (
        <Box
          background="transparent"
          minwidth="100vw"
          minheightsize="100vh"
          position="fixed"
        >
          <LoaderWrap display={authLoader} />
        </Box>
      )}
    </form>
  );
};

export default LoginForm;
