import React, { useEffect, useState } from 'react';
import Box from '../box/Box';
import Icon from '../icon/Icon';
import Text from '../text/Text';
import { types } from './helpers';
import { useTranslation } from 'react-i18next';

const NewNotification = ({ notification, type = '', onClick }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState(
    notification?.customMessage
      ? notification?.message
      : t(notification?.message)
  );
  const notificationType = !type?.length
    ? notification?.success && notification.message !== 'ErrCcc0011'
      ? 'success'
      : 'error'
    : type;

  const selectedTypeIndex = types.findIndex(
    obj => obj.key === notificationType
  );
  const toastColor = types[selectedTypeIndex]?.color;
  const toastText = types[selectedTypeIndex]?.title;

  useEffect(() => {
    if (notification?.params) {
      let newMessage = message;
      Object.entries(notification.params).forEach(([key, value]) => {
        if (newMessage.includes(key)) {
          newMessage = newMessage.replace(`{{${key}}}`, t(value));
        }
      });
      setMessage(newMessage);
    }
  }, [notification?.params]);

  return (
    <div onClick={onClick}>
      <Box display="flex" alignitems="center" justifycontent="space-between">
        <Box display="flex" alignitems="center">
          <Text fontSize="16px" fontWeight="500" color={toastColor}>
            {toastText}
          </Text>
        </Box>
        <Icon icon="x" width="12px"></Icon>
      </Box>
      <Text fontSize="14px" maxwidth="300px">
        {message}
      </Text>
    </div>
  );
};

export default NewNotification;
